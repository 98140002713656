<template>
  <b-card body>
    <div class="d-flex justify-between">
      <div class="d-flex items-center">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          @click="$router.go(-1)"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <h3 class="font-bold text-black mb-0">
          Detail Order Packing
        </h3>
      </div>
      <div class="space-x-4">
        <div class="d-flex items-center space-x-4">
          <div class="">
            <b-form-select
              v-model="ekspedisi"
              size="lg"
              :options="listEkspedisi"
            />
          </div>
          <b-button
            :variant="selected.length < 1 ? 'secondary' : 'primary'"
            :disabled="selected.length < 1"
            class="d-flex items-center space-x-2"
            @click="popupPrintLabel()"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/printer.svg"
              alt=""
            >
            <span class="font-bold">Print Label
              <span v-if="selected.length > 0">({{ selected.length }})</span>
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          id="table"
          ref="selectableTable"
          :items="orderPacking"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          :select-mode="`multi`"
          selectable
          show-empty
          @row-selected="onRowSelected"
        >
          <template #head(checkbox)>
            <BCheckbox
              v-model="isSelected"
              @change="selectAllRows()"
            />
          </template>
          <template #cell(checkbox)="data">
            <BCheckbox
              v-model="selected"
              :value="data.item"
              :disabled="data.item.is_cancel === 1"
              @change="select"
            />
          </template>
          <template #cell(tanggal_order)="data">
            <div style="min-width: 140px !important;">
              {{ formatDate(data.item.order_date) }}
              <br>
              <div style="color: #828282;">
                {{ formatTime(data.item.order_date) + ' WIB' }}
              </div>
            </div>
          </template>
          <template #cell(pelanggan)="data">
            <div style="min-width: 130px !important;">
              {{ data.item.customer_name }}
              <br>
              <div class="d-flex align-items-center">
                <img
                  class="max-h-9"
                  :src="data.item.shipment_image_path"
                  alt=""
                >
                <div class="text-xs">
                  {{ data.item.shipping_type }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(produk)="data">
            <div
              style="min-width: 700px !important;"
            >
              <div
                class="d-flex space-x-2 items-start mb-50"
              >
                <b-img
                  :src="data.item.product[0].image_path ? data.item.product[0].image_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                  fluid
                  class="image-product"
                />
                <div class="w-[70%]">
                  <div class="">
                    {{ data.item.product[0].product_name }}
                  </div>
                  <div v-if="data.item.product[0].is_bundling === 0" class="text-primary">
                    {{ data.item.product[0].variant_name }}
                  </div>
                  <div
                    v-if="data.item.product[0].is_bundling === 1"
                    class="flex mt-[4px]"
                  >
                    <div
                      v-b-toggle="`bundling-${String(data.item.detail_packing_id)}-${String(data.index)}-${String(data.item.product[0].id)}`"
                      class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                        alt="bundling"
                        width="16"
                      >
                      <div>Bundling</div>
                    </div>
                  </div>
                  <b-collapse
                    :id="`bundling-${String(data.item.detail_packing_id)}-${String(data.index)}-${String(data.item.product[0].id)}`"
                    class="min-w-max"
                  >
                    <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                      <div class="border rounded-t">
                        <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                          Produk
                        </div>
                        <b-table
                          :items="data.item.product[0].bundle_component"
                          :fields="fieldsBundling"
                        >
                          <template #cell(no)="bundle">
                            {{ bundle.index + 1 }}
                          </template>
                          <template #cell(product_name)="bundle">
                            <div class="flex gap-[16px] items-start">
                              <img
                                :src="bundle.item.product_images[0] ? bundle.item.product_images[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                alt="product"
                                width="52"
                                height="52"
                              >
                              <div class="space-y-[4px]">
                                <div class="">
                                  {{ bundle.item.product_name }}
                                </div>
                                <div class="text-[12px]">
                                  SKU: {{ bundle.item.product_sku }}
                                </div>
                              </div>
                            </div>
                          </template>
                          <template #cell(variant)="bundle">
                            {{ bundle.item.product_variant_name }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="flex ml-[0.8rem]">
                      <div
                        v-b-toggle="`bundling-${String(data.item.detail_packing_id)}-${String(data.index)}-${String(data.item.product[0].id)}`"
                        class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                        />
                        <div>Tutup</div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div class="w-[22%]">
                  {{ handleTotalProduct(data.item.product[0].total) }}
                </div>
              </div>
              <BCollapse :id="`collapse-${String(data.index)}`">
                <div
                  v-for="(item, index) in data.item.product.slice(1)"
                  :key="index"
                  class="d-flex space-x-2 items-start mb-50"
                >
                  <b-img
                    :src="item.image_path ? item.image_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                    fluid
                    class="image-product"
                  />
                  <div class="w-[70%]">
                    <div class="">
                      {{ item.product_name }}
                    </div>
                    <div v-if="item.is_bundling === 0" class="text-primary">
                      {{ item.variant_name }}
                    </div>
                    <div
                      v-if="item.is_bundling === 1"
                      class="flex mt-[4px]"
                    >
                      <div
                        v-b-toggle="`bundling-${String(data.item.detail_packing_id)}-${String(index)}-${String(item.id)}`"
                        class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                      >
                        <img
                          src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                          alt="bundling"
                          width="16"
                        >
                        <div>Bundling</div>
                      </div>
                    </div>
                    <b-collapse
                      :id="`bundling-${String(data.item.detail_packing_id)}-${String(index)}-${String(item.id)}`"
                      class="min-w-max"
                    >
                      <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                        <div class="border rounded-t">
                          <div class="bg-[#FFECE9] py-[8px] px-[28px] rounded-t text-black font-[500]">
                            Produk
                          </div>
                          <b-table
                            :items="item.bundle_component"
                            :fields="fieldsBundling"
                          >
                            <template #cell(no)="bundle">
                              {{ bundle.index + 1 }}
                            </template>
                            <template #cell(product_name)="bundle">
                              <div class="flex gap-[16px] items-start">
                                <img
                                  :src="bundle.item.product_images[0] ? bundle.item.product_images[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                  alt="product"
                                  width="52"
                                  height="52"
                                >
                                <div class="space-y-[4px]">
                                  <div class="">
                                    {{ bundle.item.product_name }}
                                  </div>
                                  <div class="text-[12px]">
                                    SKU: {{ bundle.item.product_sku }}
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template #cell(variant)="bundle">
                              {{ bundle.item.product_variant_name }}
                            </template>
                          </b-table>
                        </div>
                      </div>
                      <div class="flex ml-[0.8rem]">
                        <div
                          v-b-toggle="`bundling-${String(data.item.detail_packing_id)}-${String(index)}-${String(item.id)}`"
                          class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                        >
                          <feather-icon
                            icon="ChevronUpIcon"
                          />
                          <div>Tutup</div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="w-[22%]">
                    {{ handleTotalProduct(item.total) }}
                  </div>
                </div>
              </BCollapse>
              <div
                v-if="data.item.product.length > 1"
                v-b-toggle="`collapse-${String(data.index)}`"
                variant="flat-dark"
                size="sm"
                class="flex items-center justify-end"
              >
                <span
                  class="when-opened"
                >
                  Tutup
                </span>
                <span
                  class="when-closed"
                >
                  {{ data.item.product.slice(1).length + ' Produk lainnya' }}
                </span>
                <feather-icon
                  icon="ChevronDownIcon"
                  class="when-closed"
                />
                <feather-icon
                  icon="ChevronUpIcon"
                  class="when-opened"
                />
              </div>
            </div>
          </template>
          <template #cell(alamat)="data">
            <div style="min-width: 200px !important;">
              {{ data.item.address }}
            </div>
          </template>
          <template #cell(no_resi)="data">
            <span>{{ data.item.no_resi }}</span>
            <div
              v-if="data.item.is_print === 1"
              class="printed-badge mt-[4px]"
            >
              Tercetak
            </div>
          </template>
          <template #cell(biaya_fulfillment)="data">
            <div style="min-width: 115px !important;">
              <div
                v-if="data.item.is_cancel === 0"
                :class="data.item.order_status === 'Dipacking' ? 'text-warning' : 'text-success'"
              >
                {{ data.item.fulfillment_cost | rupiah }}
              </div>
              <div
                v-else
                class="dibatalkan-badge"
              >
                Dibatalkan
              </div>
            </div>
          </template>
        </BTable>
      </BOverlay>
    </div>
    <b-modal
      id="printLabel"
      size="xl"
      scrollable
    >
      <template
        #modal-header
      >
        <div class="w-100">
          <b-img
            src="@/assets/images/icons/close-circle.svg"
            class="absolute right-3"
            role="button"
            @click="$bvModal.hide('printLabel')"
          />
          <div class="text-center font-bold text-xl text-black my-1">
            Pilih Bentuk Print Label
          </div>
        </div>
      </template>
      <b-row class="justify-content-center">
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_1' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_1'"
          >
            <div class="label-preview">
              <div class="label-preview-top" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              1 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_2' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_2'"
          >
            <div class="label-preview">
              <div class="label-preview-top" />
              <div class="label-preview-bottom" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              2 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_4' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_4'"
          >
            <div class="label-preview">
              <div class="label-preview-topleft" />
              <div class="label-preview-topright" />
              <div class="label-preview-bottomleft" />
              <div class="label-preview-bottomright" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              4 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_5' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_5'"
          >
            <div class="label-preview">
              <div class="label-preview-full" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              10 cm x 10cm
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Printer Thermal
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_6' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_6'"
          >
            <div class="px-2">
              <div class="label-preview">
                <div class="label-preview-full" />
              </div>
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              10 cm x 15cm
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Printer Thermal
            </p>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="d-flex justify-end gap-5">
          <b-form-checkbox
            v-model="printDate"
            class="my-auto"
          >
            <span class="text-black font-bold"> Tambahkan tanggal cetak</span>
          </b-form-checkbox>
          <b-button
            variant="primary"
            class=""
            :disabled="formatPrint === ''"
            @click="downloadPrintLabel"
          >
            <b-spinner
              v-if="loadingDownload"
              small
              variant="light"
              class="mr-1"
            />
            <span
              v-if="loadingDownload"
              class="font-semibold"
            >Downloading...</span>
            <span
              v-else
              class="font-semibold"
            >Download Label</span>
          </b-button>
          <b-button @click="onFinishUpdate">
            Update
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import provider from '@/provider/provider'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import filters from '@/libs/filters'
import { OUTBOUND, SHIPMENTS, PRINT_LABEL } from '@/provider/url'
import { newAxiosIns } from '@/libs/axios'

export default {
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      loading: false,
      ekspedisi: '',

      selected: [],
      isSelected: false,

      orderPacking: [],

      orderIdPrint: null,
      formatPrint: '',
      printDate: false,
      loadingDownload: false,

      limit: 50,
      offset: 0,
      lastData: false,

      listEkspedisi: [
        {
          value: '',
          text: 'Semua Ekspedisi',
        },
      ],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'tanggal_order',
          label: 'Tanggal Order',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'pelanggan',
          label: 'Pelanggan',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'produk',
          label: 'Produk',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'alamat',
          label: 'Alamat',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'no_resi',
          label: 'No Resi',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
        {
          key: 'biaya_fulfillment',
          label: 'Biaya Fulfillment',
          thClass: 'text-black text-capitalize py-1',
          class: 'bg-white',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
          },
          tdClass: 'text-black align-top',
        },
      ],
      dateTime: this.$route.query.time,

      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
        {
          key: 'variant',
          label: 'Variasi',
          thClass:
      'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282] bg-white max-w-[100px]',
        },
        {
          key: 'qty',
          label: 'Jumlah',
          thClass:
      'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
      ],
    }
  },

  watch: {
    ekspedisi: {
      handler() {
        this.fetchDetailOrderPacking()
        this.isSelected = false
        this.selected = []
      },
    },
  },

  created() {
    this.fetchDetailOrderPacking()
    this.fetchShipment()
  },

  mounted() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('table').offsetHeight && !this.loading) {
        this.fetchNextDetailOrderPacking()
      }
    }
  },

  methods: {
    async fetchDetailOrderPacking() {
      this.offset = 0
      this.loading = true
      const params = `shipping=${this.ekspedisi}&limit=${this.limit}&offset=${this.offset}`
      await provider.list(`${OUTBOUND}/${this.$route.params.id}/detail-order?${params}`)
        .then(response => {
          const { data } = response
          this.orderPacking = data
          this.loading = false
          this.offset = data.length
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        }).catch(() => {
          this.loading = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async fetchNextDetailOrderPacking() {
      if (!this.lastData) {
        this.loading = true
        const params = `shipping=${this.ekspedisi}&limit=${this.limit}&offset=${this.offset}`
        await provider.list(`${OUTBOUND}/${this.$route.params.id}/detail-order?${params}`)
          .then(response => {
            const { data } = response
            this.orderPacking.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          }).catch(() => {
            this.loading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Gagal',
                  icon: 'AlertCircleIcon',
                  text: 'Gagal load data, silahkan coba lagi',
                  variant: 'danger',
                },
              },
              2000,
            )
          })
      }
    },
    async fetchShipment() {
      await provider.list(SHIPMENTS)
        .then(response => {
          const { data } = response
          const ekspedisi = this.listEkspedisi.concat(data.map(obj => ({
            value: obj.shipping_name,
            text: obj.shipping_name,
          })))
          this.listEkspedisi = ekspedisi
        }).catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal load data, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    onRowSelected(items) {
      this.selected = items.filter(obj => obj.is_cancel === 0)
      if (items.length > 0 && items.length === this.orderPacking.length) this.isSelected = true
      if (items.length !== this.orderPacking.length) this.isSelected = false
    },
    selectAllRows() {
      if (this.isSelected) {
        this.$refs.selectableTable.selectAllRows()
      }
      if (!this.isSelected) {
        this.$refs.selectableTable.clearSelected()
      }
    },
    select(value) {
      this.isSelected = false
      this.selected = value
    },
    popupPrintLabel() {
      this.$bvModal.show('printLabel')
      let orderIdPrint = null

      for (let i = 0; i < this.selected.length; i += 1) {
        if (i === this.selected.length - 1) {
          orderIdPrint += `${this.selected[i].order_id}`
        } else {
          orderIdPrint += `${this.selected[i].order_id},`
        }
      }
      this.orderIdPrint = orderIdPrint
    },
    async downloadPrintLabel() {
      const orderId = this.selected.map(obj => obj.order_id).toString()
      this.loadingDownload = true
      const formData = new FormData()
      const dataBody = [
        {
          name: 'order_id',
          value: orderId,
        },
        {
          name: 'page',
          value: this.formatPrint,
        },
        {
          name: 'print_date',
          value: this.printDate ? 1 : 0,
        },
      ]
      dataBody.map(item => formData.append(item.name, item.value))
      await newAxiosIns.post(`/label/api/${PRINT_LABEL}`, formData)
        .then(res => {
          this.onFinishUpdate()
          const { base_64, path } = res.data.data

          // eslint-disable-next-line camelcase
          const linkSource = `data:application/pdf;base64,${base_64}`
          const downloadLink = document.createElement('a')
          const fileName = `label-${this.dateTime}.pdf`

          downloadLink.href = linkSource
          downloadLink.download = fileName

          downloadLink.click()

          window.open(path, '_blank')

          this.loadingDownload = false
          this.$bvModal.hide('printLabel')
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal download label, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
          this.loadingDownload = false
        })
    },
    async onFinishUpdate() {
      const packingID = this.selected.map(obj => obj.detail_packing_id)
      const payload = {
        detail_packing_id: packingID,
      }
      await provider.update(`${OUTBOUND}/print`, payload)
        .then(() => {})
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal update print, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    formatDate(value) {
      const newDate = value.substring(0, value.lastIndexOf(' '))
      return moment(newDate).format('DD MMMM YYYY')
    },
    formatTime(value) {
      const newTime = value.substring(value.indexOf(' ') + 1)
      return newTime
    },
    handleTotalProduct(value) {
      if (value !== undefined) {
        return `x${value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1.')}`
      }
      return value
    },
  },
}
</script>

<style scoped>
.printed-badge {
  border: 1px solid #34A770;
  border-radius: 10px;
  height: 20px;
  color: #007F00;
  text-align: center;
  background-color: #85F3BE;
}

.collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
.image-product{
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}

.dibatalkan-badge {
  background-color: #FFECE9;
  padding: 5px;
  border-radius: 14px;
  color: #E31A1A;
  text-align: center;
}

.custom-control-label::before{
  border: 1px solid #828282;
}

#printLabel.modal{
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
}
.card-print-label{
  border: 2px solid #E2E2E2;
  border-radius: 6px;
  width: 280px;
  margin: 7px;
  display: inline-block;
  text-align: left;
  padding: 7px;
}
.card-print-label:hover{
  border: 2px solid #F95031;
}
.card-print-label:hover .label-preview{
  border: 1px solid #F95031;
}
.card-print-label:hover .label-preview-full{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-top{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-topleft{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-topright{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottom{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottomleft{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottomright{
  background-color: #FFECE9;
}
.card-print-label.active{
  border: 2px solid #F95031;
}
.card-print-label.active .label-preview{
  border: 1px solid #F95031;
}
.card-print-label.active .label-preview-full{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-top{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-topleft{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-topright{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottom{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottomleft{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottomright{
  background-color: #FFECE9;
}
.label-preview{
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  height: 240px;
  width: 100%;
  padding: 10px;
}
.label-preview-full{
  background-color: #E2E2E2;
  height: 100%;
  border-radius: 6px;
}
.label-preview-top{
  background-color: #E2E2E2;
  height: 48%;
  margin-bottom: 4%;
  border-radius: 6px;
}
.label-preview-topleft{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-bottom: 2%;
  margin-right: 2%;
  border-radius: 6px;
}
.label-preview-topright{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-bottom: 2%;
  margin-left: 2%;
  border-radius: 6px;
}
.label-preview-bottom{
  background-color: #E2E2E2;
  height: 48%;
  margin-bottom: 2%;
  border-radius: 6px;
}
.label-preview-bottomleft{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-top: 2%;
  margin-right: 2%;
  border-radius: 6px;
}
.label-preview-bottomright{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-top: 2%;
  margin-left: 2%;
  border-radius: 6px;
}
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}

@media (max-width: 576px) {
  .card-print-label {
    width: 200px!important;
  }

  .label-preview {
    height: 200px!important;
  }
}
</style>
